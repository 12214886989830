<template>

<!-- <div>    
        <h2>Login</h2>    
        <form v-on:submit="preventPost">    
            <input type="text" v-model="email" name="email" /><br>    
            <input type="password" v-model="password" name="password" /><br>    
            <input type="submit" value="Login" v-on:click="doLogin" />    
        </form>    
    </div> -->

<v-app> 
<v-container>
  <v-row>
    <v-col align="center">
      <v-card class="elevation-12" max-width="500">
        <v-toolbar dark color="secondary">
          <v-toolbar-title>Login form</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form @submit="preventPost">
            <v-text-field 
            prepend-icon="mdi-account"
            type="text"
            v-model="email"
            placeholder="login"
            >
            </v-text-field>
            <v-text-field  
            prepend-icon="mdi-lock" 
            v-model="password"
            type="password"
            placeholder="password"
            >
            </v-text-field>  
          <v-spacer></v-spacer>
          <v-btn type="submit" class="ma-2" color="secondary" @click="doLogin">Login</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</v-app>

</template>

<script>
 export default {
   name: "Login",
   data() {
     return {
       email: "",
       password: "",
     }
   },
   methods: {
     doLogin() {
       this.$store.dispatch("loginUser", ({
         email: this.email,
         password: this.password,
       }));
     },
     preventPost: (e) => {
       e.preventDefault();
     },
   },
 }
</script>
